var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.download)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-1 download-button",attrs:{"disabled":_vm.disableDownload,"color":"error","icon":""},on:{"click":function($event){return _vm.$emit('onDownload', _vm.itemId)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,false,3663587207)},[_c('span',[_vm._v("Download PDF")])]):_vm._e(),(_vm.info)?_c('router-link',{attrs:{"to":{ name: (_vm.type + "-item"), params: { id: _vm.itemId } }},nativeOn:{"click":function($event){return $event.stopImmediatePropagation()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-1",attrs:{"color":"info","icon":""}},on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,false,526513068)},[_c('span',[_vm._v("View")])])],1):_vm._e(),(_vm.edit)?_c('router-link',{attrs:{"to":{ name: (_vm.type + "-edit"), params: { id: _vm.itemId } }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-1",attrs:{"color":"success","icon":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,3753971279)},[_c('span',[_vm._v("Edit")])])],1):_vm._e(),(_vm.toInvoice)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-1",attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.$emit('onToInvoice', _vm.itemId)}}},on),[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)]}}],null,false,2264010839)},[_c('span',[_vm._v("Create invoice")])]):_vm._e(),(_vm.remove)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-1 remove-button",attrs:{"disabled":_vm.disableRemove,"color":"error","icon":""},on:{"click":function($event){return _vm.$emit('onRemove', _vm.itemId)}}},on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,false,3712332476)},[_c('span',[_vm._v("Remove")])]):_vm._e(),(_vm.take)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-1 take-button",attrs:{"disabled":_vm.disableTake,"color":"success","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('onTake', _vm.itemId)}}},on),[_c('v-icon',[_vm._v("mdi-handshake")])],1)]}}],null,false,2135147058)},[_c('span',[_vm._v("Take")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }