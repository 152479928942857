var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ItemWrapper',[_c('v-row',{attrs:{"justify-start":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"4","xl":"3"}},[_c('AppCard',{attrs:{"loading":_vm.pending.getClient,"prev-route":_vm.prevRoute,"title":_vm.$t('Personal Data / Handles')}},[_c('v-expansion-panels',{attrs:{"value":0}},[_vm._l((_vm.handleTypes),function(ref){
var label = ref.label;
var key = ref.key;
return [_c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t(label))+" "),(_vm.client[(key + "_handle")].active)?_c('v-chip',{staticClass:"flex-grow-0 ml-4",attrs:{"x-small":"","color":"success","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('activated'))+" ")]):_c('v-chip',{staticClass:"flex-grow-0 ml-4",attrs:{"x-small":"","color":"error","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('deactivated'))+" ")])],1),_c('v-expansion-panel-content',[_c('address',[(_vm.client[(key + "_handle")].organisation)?_c('b',[_vm._v(_vm._s(_vm.client[(key + "_handle")].organisation))]):_vm._e(),_c('br'),(_vm.client[(key + "_handle")].sex == 'MALE')?_c('span',[_vm._v("Herr")]):_vm._e(),(_vm.client[(key + "_handle")].sex == 'FEMALE')?_c('span',[_vm._v("Frau")]):_vm._e(),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].firstname)+" "+_vm._s(_vm.client[(key + "_handle")].lastname)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].street)+" "+_vm._s(_vm.client[(key + "_handle")].number)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].zip)+" "+_vm._s(_vm.client[(key + "_handle")].city)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].country)+" "),_c('br'),_c('b',[_vm._v("Phone:")]),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].phone)+" "),_c('br'),_c('b',[_vm._v("Fax:")]),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].fax)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.client[(key + "_handle")].unique_name)+" ")])])],1)]})],2)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('AppCard',{attrs:{"title":_vm.$t('Invoices')}},[_c('v-data-table',{attrs:{"headers":_vm.invoiceTableHeaders,"items":_vm.invoices,"loading":_vm.pending.getClient,"hide-default-footer":_vm.invoices.length <= 5},scopedSlots:_vm._u([{key:"item.billingdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.billingdate,'DD.MM.YYYY'))+" ")]}},{key:"item.due_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.billingdate,'add', '14 days', 'DD.MM.YYYY'))+" ")]}},{key:"item.vkp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$n(item.vkp, 'currency', 'de-DE')))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('InvoiceRowStateButtons',{attrs:{"invoice":item,"disabled-buttons":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('InvoiceRowActions',{attrs:{"invoice":item,"disable-remove":""}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }