<template>
  <InvoiceStateButtons
    v-if="invoice.status !== 'canceled' && invoice.status !== 'closed'"
    :status="invoice.status"
    :disabled-buttons="isInvoicePending || disabled"
    @onChangeState="changeState"
  />
  <v-chip v-else color="error" label>
    {{ invoice.status }}
  </v-chip>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

import InvoiceStateButtons from '@/components/Invoices/InvoiceStateButtons'

export default defineComponent({
  name: 'InvoiceRowStateButtons',
  components: {
    InvoiceStateButtons,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    pendingInvoices: {
      type: Array,
      default: _ => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isInvoicePending = computed(_ => props.pendingInvoices.indexOf(props.invoice.id) !== -1)

    const changeState = state => {
      emit('onChangeState', state)
    }

    return {
      isInvoicePending,
      changeState,
    }
  },
})
</script>

<style lang="scss" scoped>
table .label {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn.btn-primary {
  margin: 0;
}
// .btn-group.state > .btn {
//   margin-top: 10px;
//   margin-bottom: 0;
// }
</style>
