<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on }">
        <v-btn
          small
          :disabled="disabledButtons"
          :class="{
            warning: status === 'draft',
            info: status === 'open',
            success: status === 'payed',
          }"
          class="state-button"
          v-on="on"
        >
          {{ status }}
          <v-icon right dark>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="status !== 'open' && status !== 'storno'" dense @click="changeState('open')">
          <v-list-item-title>Open</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="status === 'open' && status !== 'storno'" dense @click="changeState('closed')">
          <v-list-item-title>Closed</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="status === 'open' && status !== 'storno'" dense @click="changeState('payed')">
          <v-list-item-title>Payed</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="status === 'payed'" dense @click="changeState('canceled')">
          <v-list-item-title>Storno</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'InvoiceStateButtons',
  props: {
    status: {
      type: String,
      required: true,
    },
    disabledButtons: Boolean,
  },
  setup(props, { emit }) {
    const changeState = state => {
      emit('onChangeState', state)
    }

    return {
      changeState,
    }
  },
})
</script>

<style lang="scss" scoped>
.btn-group.state > .btn {
  margin-top: 10px;
  margin-bottom: 0;
}
</style>
