<template>
  <div>
    <v-tooltip v-if="download" top>
      <template #activator="{ on }">
        <v-btn
          :disabled="disableDownload"
          class="pa-1 ma-1 download-button"
          color="error"
          icon
          v-on="on"
          @click="$emit('onDownload', itemId)"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
      <span>Download PDF</span>
    </v-tooltip>

    <router-link
      v-if="info"
      :to="{ name: `${type}-item`, params: { id: itemId } }"
      @click.native="$event.stopImmediatePropagation()"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="pa-1 ma-1" color="info" icon v-on="on">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>View</span>
      </v-tooltip>
    </router-link>

    <router-link v-if="edit" :to="{ name: `${type}-edit`, params: { id: itemId } }">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="pa-1 ma-1" color="success" icon v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </router-link>

    <v-tooltip v-if="toInvoice" top>
      <template #activator="{ on }">
        <v-btn class="pa-1 ma-1" color="success" icon @click="$emit('onToInvoice', itemId)" v-on="on">
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
      </template>
      <span>Create invoice</span>
    </v-tooltip>

    <v-tooltip v-if="remove" top>
      <template #activator="{ on }">
        <v-btn
          :disabled="disableRemove"
          class="pa-1 ma-1 remove-button"
          color="error"
          icon
          v-on="on"
          @click="$emit('onRemove', itemId)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <span>Remove</span>
    </v-tooltip>

    <v-tooltip v-if="take" top>
      <template #activator="{ on }">
        <v-btn
          :disabled="disableTake"
          class="pa-1 ma-1 take-button"
          color="success"
          icon
          v-on="on"
          @click.stop="$emit('onTake', itemId)"
        >
          <v-icon>mdi-handshake</v-icon>
        </v-btn>
      </template>
      <span>Take</span>
    </v-tooltip>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OverviewActions',
  props: {
    type: {
      type: String,
      default: null,
    },
    itemId: {
      type: [
        Number,
        String,
      ],
      required: true,
    },
    info: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    toInvoice: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    disableDownload: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    disableRemove: {
      type: Boolean,
      default: false,
    },
    take: {
      type: Boolean,
      default: false,
    },
    disableTake: {
      type: Boolean,
      default: false,
    },
  },
}) //
</script>
