<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12" sm="12" lg="4" xl="3">
        <AppCard :loading="pending.getClient" :prev-route="prevRoute" :title="$t('Personal Data / Handles')">
          <v-expansion-panels :value="0">
            <template v-for="{ label, key } in handleTypes">
              <v-expansion-panel :key="key">
                <v-expansion-panel-header>
                  {{ $t(label) }}
                  <v-chip
                    v-if="client[`${key}_handle`].active"
                    x-small
                    class="flex-grow-0 ml-4"
                    color="success"
                    text-color="white"
                  >
                    {{ $t('activated') }}
                  </v-chip>
                  <v-chip v-else x-small class="flex-grow-0 ml-4" color="error" text-color="white">
                    {{ $t('deactivated') }}
                  </v-chip>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <address>
                    <b v-if="client[`${key}_handle`].organisation">{{ client[`${key}_handle`].organisation }}</b>
                    <br />
                    <span v-if="client[`${key}_handle`].sex == 'MALE'">Herr</span>
                    <span v-if="client[`${key}_handle`].sex == 'FEMALE'">Frau</span>
                    {{ client[`${key}_handle`].firstname }}
                    {{ client[`${key}_handle`].lastname }}
                    <br />
                    {{ client[`${key}_handle`].street }}
                    {{ client[`${key}_handle`].number }}
                    <br />
                    {{ client[`${key}_handle`].zip }}
                    {{ client[`${key}_handle`].city }}
                    <br />
                    {{ client[`${key}_handle`].country }}
                    <br />
                    <b>Phone:</b>
                    {{ client[`${key}_handle`].phone }}
                    <br />
                    <b>Fax:</b>
                    {{ client[`${key}_handle`].fax }}
                    <br />
                    {{ client[`${key}_handle`].email }}
                    <br />
                    {{ client[`${key}_handle`].unique_name }}
                  </address>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </AppCard>
      </v-col>

      <!-- <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending.getClient" :title="$t('Sales')">
          <p>Umsatz anzeigen</p>
        </AppCard>
      </v-col> -->

      <!-- <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending.getClient" :title="$t('Actions')">
          <p>
            Bearbeiten
            <br />
            Löschen
            <br />
            Sperren / Entsperren
            <br />
            Nachricht senden
            <br />
            Ticket eröffnen
            <br />
            Rechnung schreiben
          </p>
        </AppCard>
      </v-col> -->

      <!-- <v-col cols="12" sm="6" lg="6" xl="3">
        <AppCard :loading="pending.getClient" :title="$t('Notes')">
          <v-form @submit.prevent="onSubmitNotes">
            <v-container py-0>
              <v-row wrap>
                <v-col cols="12">
                  <v-textarea v-model="notes" label="Notes" />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn class="mx-0 font-weight-light" color="success">Update Notes</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </AppCard>
      </v-col> -->

      <v-col cols="12">
        <AppCard :title="$t('Invoices')">
          <v-data-table
            :headers="invoiceTableHeaders"
            :items="invoices"
            :loading="pending.getClient"
            :hide-default-footer="invoices.length <= 5"
          >
            <template #[`item.billingdate`]="{ item }">
              {{ item.billingdate | moment('DD.MM.YYYY') }}
            </template>

            <template #[`item.due_at`]="{ item }">
              {{ item.billingdate | moment('add', '14 days', 'DD.MM.YYYY') }}
            </template>

            <template #[`item.vkp`]="{ item }">{{ $n(item.vkp, 'currency', 'de-DE') }}</template>

            <template #[`item.status`]="{ item }">
              <InvoiceRowStateButtons :invoice="item" disabled-buttons />
            </template>

            <template #[`item.actions`]="{ item }">
              <InvoiceRowActions :invoice="item" disable-remove />
            </template>
          </v-data-table>
        </AppCard>
      </v-col>

      <!-- <v-col cols="12" lg="6">
        <AppCard :loading="pending.getClient" :title="$t('Products')">
          <p>Auflistung laufender Produkte, z.B. Domain</p>
        </AppCard>
      </v-col> -->

      <!-- <v-col cols="12" lg="6">
        <AppCard :loading="pending.getClient" :title="$t('Files')">
          <p>Kunden spezifische Datein, z.B. Veträge</p>
        </AppCard>
      </v-col> -->
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import InvoiceRowActions from '@/components/Invoices/InvoiceRowActions.vue'
import InvoiceRowStateButtons from '@/components/Invoices/InvoiceRowStateButtons.vue'

export default defineComponent({
  name: 'ViewClient',
  components: {
    AppCard,
    ItemWrapper,
    InvoiceRowActions,
    InvoiceRowStateButtons,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const notes = ref('')
    const prevRoute = ref('/Clients')
    const handleTypes = [
      {
        key: 'contact',
        label: 'Contact Handle',
      },
      {
        key: 'billing',
        label: 'Billing Handle',
      },
      {
        key: 'admin',
        label: 'Admin Handle',
      },
    ]
    const invoiceTableHeaders = [
      {
        sortable: false,
        text: 'Invoice Nr.',
        value: 'invoice_nr',
      },
      {
        sortable: false,
        text: 'Billingdate',
        value: 'billingdate',
      },
      {
        sortable: false,
        text: 'Amount',
        value: 'vkp',
      },
      {
        sortable: false,
        text: 'Due at',
        value: 'due_at',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    // store
    const pending = computed(_ => $store.state.client.pending)
    const client = computed(_ => $store.state.client.client)
    const invoices = computed(_ => $store.state.client.invoices)
    const getClient = _ => $store.dispatch('client/getClient')
    const clearClient = _ => $store.dispatch('client/clearClient')

    getClient()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    onBeforeUnmount(_ => {
      clearClient()
    })

    const onSubmitNotes = _ => {
      // TODO: saveNotes
    }

    return {
      notes,
      prevRoute,
      handleTypes,
      pending,
      client,
      invoices,
      invoiceTableHeaders,
      onSubmitNotes,
    }
  },
}) //
</script>
